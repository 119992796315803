import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';

function AcadimicsCalender(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/acadimicscalender")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Academics Calendar</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="container">
<div class="row">
  <div class="col-md-12 mt-5">
  <strong>Academic Calendar:</strong><br />
    <p>
    The Academic Calendar outlines the schedule of academic activities for the year, including important dates for exams, holidays, and events. Stay informed about all the key dates to ensure you don’t miss out on any academic or extracurricular activities.
    </p>
    <strong>Features:</strong><br />
    <p>- Semester Dates</p>
    <p>- Examination Schedules</p>
    <p>- Holiday List</p>
    <p>- Event Calendar</p>
  </div>
  </div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
 </div>
        <Footer />
        </>
    );
}

export default AcadimicsCalender;