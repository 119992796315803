import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";

function Sports() {
    return (
        <>
            <Header />
            <div className="section">
                <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                    <div class="pattern-overlay">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 title-heading">
                                    <h2>Sports</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="section margin-top_50">
                    <div class="container">
                        <div class="row">
                            <div className="col-md-12 layout_padding_2">
                                <div className="full">
                                    <div className="heading_main text_align_left">
                                        <h2>
                                             SDWG Government College Beetan
                                        </h2>
                                    </div>
                                    <div className="full">
                                        <p>
                                            At SDWG Government College Beetan, we recognize the vital role of sports in shaping well-rounded individuals. Our college promotes a vibrant sports culture by providing state-of-the-art facilities and organizing intercollegiate events to encourage active participation. From athletics to team sports, we strive to foster teamwork, discipline, and a spirit of healthy competition. Join us in celebrating the passion for sports and nurturing champions of tomorrow!
                                        </p>
                                        <p>
                                            Our college boasts a well-equipped and diverse sports infrastructure to cater to the needs of aspiring athletes and fitness enthusiasts:
                                        </p>
                                    </div>
                                    <div class="container my-5">
                                        <ul class="list-unstyled">
                                            <li class="mb-3">
                                                <strong>Table Tennis:</strong>
                                                <ul class="list-unstyled ps-3">
                                                    <li>Indoor facility with professional-grade table.</li>
                                                    <li>Spacious, well-lit room for uninterrupted play.</li>
                                                </ul>
                                            </li>
                                            <li class="mb-3">
                                                <strong>Chess:</strong>
                                                <ul class="list-unstyled ps-3">
                                                    <li>Dedicated indoor space for chess enthusiasts.</li>
                                                    <li>High-quality chessboards for practice.</li>
                                                </ul>
                                            </li>
                                            <li class="mb-3">
                                                <strong>Badminton:</strong>
                                            </li>
                                            <li class="mb-3">
                                                <strong>Boxing Ring:</strong>
                                                <ul class="list-unstyled ps-3">
                                                    <li>Full-sized boxing ring adhering to professional standards.</li>
                                                </ul>
                                            </li>
                                            <li class="mb-3">
                                                <strong>Kabaddi Mats:</strong>
                                                <ul class="list-unstyled ps-3">
                                                    <li>High-quality kabaddi mats for safe and professional play.</li>
                                                </ul>
                                            </li>
                                            <li class="mb-3">
                                                <strong>Wrestling Mats:</strong>
                                                <ul class="list-unstyled ps-3">
                                                    <li>Professional-grade wrestling mats for training and matches.</li>
                                                    <li>Ample space for warm-ups and practice.</li>
                                                </ul>
                                            </li>
                                            <li class="mb-3">
                                                <strong>Gymnasium:</strong>
                                                <ul class="list-unstyled ps-3">
                                                    <li>Fully-equipped gym with modern fitness machines and free weights.</li>
                                                    <li>
                                                        Separate sections for cardio, strength training, and flexibility
                                                        exercises.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="mb-3">
                                                <strong>Playground:</strong>
                                                <ul class="list-unstyled ps-3">
                                                    <li>Large outdoor playground for sports like cricket, football, and athletics.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <p class="mt-4">
                                            Our sports facilities are designed to encourage participation, foster teamwork,
                                            and prepare students for regional, state, and national competitions.
                                        </p>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <Footer />
        </>
    );
}

export default Sports;