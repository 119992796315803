import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';

function Scholarship(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/scholarship")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Scholarship</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div className="container mt-5 pb-5">
    <div className="schloarship-main">
        <p>A number of scholarship schemes are available for eligible and deserving students of the college. These scholarships are designed to provide financial assistance to needy students and to recognise academic excellence. The students can explore these scholarship opportunities and can submit the application forms through centralized platform for various scholarships i.e.  National Scholarship Portal. <a href="https://scholarships.gov.in/" target="_blank">(https://scholarships.gov.in/).</a> The college regularly notifies the students regarding opening –closing dates of schemes, eligibility conditions and procedure of applying for the various schemes. The National Scholarship Portal provides Central, State, UGC/AICTE Schemes for the different categories of students like SC, ST, OBC, EBC, and Minority and for meritorious students etc. Every year various students of the college apply for these schemes and get benefitted.</p>
    </div>
</div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
        <Footer />
        </>
    );
}

export default Scholarship;