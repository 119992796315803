import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import topback from "../images/beetitle.png";

import { useEffect, useState } from 'react';
function Aishe(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/aishe")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
           <Header />
        <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>AISHE</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>

<section class="container mt-5 mb-5">
<div class="row">
  <div class="col-md-12">
    <p>
    The All India Survey on Higher Education (AISHE) collects and compiles data on various aspects of higher education institutions in India. Our college participates in AISHE to contribute to the national database of higher education statistics.
    </p>
    <strong>Features:</strong><br />
    <p>- Survey Data</p>
    <p>- Institutional Information</p>
    <p>- Performance Indicators</p>
    <p>- Statistical Reports</p>
  </div>
  </div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
</section>
<Footer />
        </>
        );

}

export default Aishe;