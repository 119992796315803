import React from "react";
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import topback from "../../images/beetitle.png";
import Teacherimg from "../../images/teacher/historyteacher.jpg";

function History(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>History Department</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="our-webcoderskull mt-5">
  <div class="container">
    <div class="section-text">
        <h4 className="text-center"><i>“History is not a burden on the memory, but an illumination of the soul”</i></h4>
    <p>
    The Department of History was established in the Institution way back in 1997,when SDWG GC Beetan came into existence. At present one teaching post is sanctioned for the Department. History has remained a subject of prime consideration for the students over the years since it enables them to strengthen their academic profile besides preparing them for all sorts of competitive exams. Amongst different subjects available for studies in the Arts stream,History has remained the most popular course for UG students and a large fraction of new incumbents prefer to choose it either as their Major or Minor course every academic year.Currently a healthy number of students are  pursuing their Bachelor Degree [BA with pass course] with History as an Elective subject. The scope and viability of this subject is far-reaching as it nourishes you with the past and nestles you for the future. 
    </p>
    </div>
    </div>
  </section>
<section class="our-webcoderskull">
  <div class="container">
    <div class="row heading heading-icon">
       
    </div>
    <ul class="row padding-end" style={{paddingLeft: '0px'}}>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={Teacherimg} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Ms.Jyoti Sharma</a></h3>
            <p>Assistant Professor</p>
            <p>(Department of History )</p>
          </div>
      </li>
    </ul>
  </div>
</section>
        <Footer />
        </>
    );
}

export default History;