import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import SUNITA from "../images/teacher/SUNITA.webp";
import PAWAN from "../images/teacher/PAWAN.webp";
import AVTAR from "../images/teacher/avtar.png";

function Administationstaff(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Administation Staff</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="our-webcoderskull padding-lg">
  <div class="container">
    <div class="row heading heading-icon">
        <h2></h2>
    </div>
    <ul class="row padding-end" style={{paddingLeft: '0px'}}>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={SUNITA} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Smt. Sunita Rani</a></h3>
            <p>(LIBRARIAN)</p>
            <p>Mobile No: (9872676931)</p>
            {/* <ul class="follow-us clearfix">
              <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul> */}
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={PAWAN} class="img-responsive" alt="" /></figure>
            <h3><a href="#">MR.PAWAN KUMAR</a></h3>
            <p>(ASSISTANT LIBRARIAN)</p>
            <p>Mobile No: (9463979175)</p>
          </div>
      </li>
      {/* <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={AVTAR} class="img-responsive" alt="" /></figure>
            <h3><a href="#">SH. Piar Chand</a></h3>
            <p>(Superintendent Grade-1)</p>
            <p>Mobile No: (8544733051)</p>
          </div>
      </li> */}
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={AVTAR} class="img-responsive" alt="" /></figure>
            <h3><a href="#">SH. Ashok Kumar</a></h3>
            <p>(Superintendent Grade-11)</p>
            <p>Mobile No: (9805191228)</p>
          </div>
       </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={AVTAR} class="img-responsive" alt="" /></figure>
            <h3><a href="#">SH. Ashok Kumar</a></h3>
            <p>(Storekeeper)</p>
            <p>Mobile No: (9805509984)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={AVTAR} class="img-responsive" alt="" /></figure>
            <h3><a href="#">SH. Ram Kumar</a></h3>
            <p>(Library Attendant)</p>
            <p>Mobile No: (6230624895)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={AVTAR} class="img-responsive" alt="" /></figure>
            <h3><a href="#">SH. Karam Chand</a></h3>
            <p>(Gardener(Mali))</p>
            <p>Mobile No: (8894192975)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={AVTAR} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Sh. Paramjeet Singh </a></h3>
            <p>(Peon-cum-Chowkidar)</p>
            <p>Mobile No: (9816642290)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={AVTAR} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Sh. Vijay Kumar</a></h3>
            <p>(Peon-cum-Chowkidar(Daily wager))</p>
            <p>Mobile No: (8219461103)</p>
          </div>
      </li>
    
    </ul>
  </div>
</section>
<Footer />
        </>
    );
}

export default Administationstaff;