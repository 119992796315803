import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";

function SGRC() {
    return (
        <>
            <Header />
            <div className="section">
                <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                    <div class="pattern-overlay">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 title-heading">
                                    <h2>SGRC</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="container my-5">
                    <h3 class="mb-4">(SGRC)/Ombudsperson: Details of Student Grievance Redressal Committee</h3>
                    <p>
                        Student Grievance Redressal Committee (SGRC) has been constituted in the college,
                        whose Chairperson is <strong>Mr. Gurdev Singh</strong>. The members include
                        <strong>Dr. Uma Rani</strong>, <strong>Dr. Gaurav Sharma</strong>, and
                        <strong>Dr. Daleep Bali</strong>. Additionally, <strong>CSCA president Ms. Riya</strong>
                        is included in the committee as a special invitee.
                    </p>

                    <h4 class="mt-4">Key Objectives of SGRC:</h4>
                    <ul class="list-unstyled ps-3">
                        <li class="mb-2">
                            1) To provide an opportunity for the students to freely express their grievance,
                            with utmost anonymity.
                        </li>
                        <li class="mb-2">
                            2) To set up a mechanism for speedy and expeditious resolution of the grievance.
                        </li>
                        <li class="mb-2">
                            3) To provide appropriate counseling to the students in the process of resolving
                            the grievance.
                        </li>
                    </ul>
                </div>

            </div>


            <Footer />
        </>
    );
}

export default SGRC;