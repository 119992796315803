import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import AR1 from "../pdf/वार्षिक_रिपोर्ट_2023-24.pdf"
import pdficon from "../images/pdf-icon.jpg";
function Annual() {
    return (
        <>
            <Header />
            <div className="section">
                <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                    <div class="pattern-overlay">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 title-heading">
                                    <h2>Annual Report</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="container">
                    <div class="row">
                        <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{ width: '128px;' }} /></div>
                        <div class="col-6">
                            <p className="pdfpara"><a href={AR1} target="_blank"><strong>Annual Report 2023-24(pdf)</strong></a></p>
                        </div>
                        <div class="col-2 text-center justify-content-center align-self-center">
                            <div class="progress d-flex align-items-lg-center">
                            </div>
                        </div>
                        <div class="col-2 align-self-center"><a href={AR1} target="_blank" class="btn btn-back" style={{ width: '150px;', height: '36px;' }}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
                    </div>
                </div>

            </div>


            <Footer />
        </>
    );
}

export default Annual;