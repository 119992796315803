import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import Collab0 from "../images/Collaboration.jpg"
import Collab1 from "../images/Collab1.jpg"
import Collab2 from "../images/Collab2.jpg"
import Collab3 from "../images/Collab3.jpg"
import Collab4 from "../images/Collab4.jpg"
import Collab5 from "../images/Collab5.jpg"
import Collab6 from "../images/Collab6.jpg"
import Collab7 from "../images/Collab7.jpg"
import Collab8 from "../images/Collab8.jpg"
import Collab9 from "../images/Collab9.jpg"
import Collab10 from "../images/Collab10.jpg"

function Collaboration() {
    return (
        <>
            <Header />
            <div className="section">
  {/* <!--Video Section--> */}
  <section className="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
    <div className="pattern-overlay">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 title-heading">
            <h2>Collaboration</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* <!--Video Section Ends Here--> */}

  {/* <!-- section --> */}
  <div className="section margin-top_50">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <p>
            <strong>
              At SDWG Government College Beetan, we believe in the power of partnerships to enhance education and foster innovation. Our collaborations with academic institutions, industries, and organizations aim to create opportunities for knowledge exchange, skill development, and research excellence. Together, we strive to empower students, contribute to society, and build a brighter future. Let's join hands to achieve shared goals and nurture talent that makes a difference.
            </strong>
          </p>
        </div>
      </div>
      <div className="row">
        {/* Gallery Images */}
        <div className="col-md-4">
          <a className="gallery__item fresco" href={Collab0} data-fresco-group="gallery">
            <img src={Collab0} alt="Collaboration 1" className="img-fluid mb-3" />
          </a>
        </div>
        <div className="col-md-4">
          <a className="gallery__item fresco" href={Collab1} data-fresco-group="gallery">
            <img src={Collab1} alt="Collaboration 2" className="img-fluid mb-3" />
          </a>
        </div>
        <div className="col-md-4">
          <a className="gallery__item fresco" href={Collab2} data-fresco-group="gallery">
            <img src={Collab2} alt="Collaboration 3" className="img-fluid mb-3" />
          </a>
        </div>
        <div className="col-md-4">
          <a className="gallery__item fresco" href={Collab3} data-fresco-group="gallery">
            <img src={Collab3} alt="Collaboration 4" className="img-fluid mb-3" />
          </a>
        </div>
        <div className="col-md-4">
          <a className="gallery__item fresco" href={Collab4} data-fresco-group="gallery">
            <img src={Collab4} alt="Collaboration 5" className="img-fluid mb-3" />
          </a>
        </div>
        <div className="col-md-4">
          <a className="gallery__item fresco" href={Collab5} data-fresco-group="gallery">
            <img src={Collab5} alt="Collaboration 6" className="img-fluid mb-3" />
          </a>
        </div>
        <div className="col-md-4">
          <a className="gallery__item fresco" href={Collab6} data-fresco-group="gallery">
            <img src={Collab6} alt="Collaboration 7" className="img-fluid mb-3" />
          </a>
        </div>
        <div className="col-md-4">
          <a className="gallery__item fresco" href={Collab7} data-fresco-group="gallery">
            <img src={Collab7} alt="Collaboration 8" className="img-fluid mb-3" />
          </a>
        </div>
        <div className="col-md-4">
          <a className="gallery__item fresco" href={Collab8} data-fresco-group="gallery">
            <img src={Collab8} alt="Collaboration 9" className="img-fluid mb-3" />
          </a>
        </div>
        <div className="col-md-4">
          <a className="gallery__item fresco" href={Collab9} data-fresco-group="gallery">
            <img src={Collab9} alt="Collaboration 10" className="img-fluid mb-3" />
          </a>
        </div>
        <div className="col-md-4">
          <a className="gallery__item fresco" href={Collab10} data-fresco-group="gallery">
            <img src={Collab10} alt="Collaboration 11" className="img-fluid mb-3" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

            {/* <!-- end section --> */}



            <Footer />
        </>
    );
}

export default Collaboration;