import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";

import topback from "../images/beetitle.png";
import AboutIMG from "../images/principalbeetan.JPG";

function About() {
  return (
    <>
      <Header />
      <div className="section">
        {/* <!--Video Section--> */}
        <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
          <div class="pattern-overlay">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 title-heading">
                  <h2>ABOUT</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--Video Section Ends Here--> */}
        {/* <!-- section --> */}
        <div class="section margin-top_50">
          <div class="container">
          <div class="row">

            <div className="col-md-8 layout_padding_2" style={{width:'100%'}}>
              <div className="full">
                <div className="heading_main text_align_left">
                  <h2><span>Welcome To</span> Beetan College</h2>
                </div>
                <div className="full">
                  <p>S.D.W.G.Government Degree College Beetan was established on 23rd August, 2004 when the Government of Himachal Pradesh took it over from the private management of Sant Baba Dhangu Wale Ji. Ever since its inception in 1997, it has been imparting quality education to the students of the area. The College has been accredited by the NAAC with grade “B” in December 2016.  It possesses a sprawling campus and is surrounded by lush green fields. The scenic setting provides an ideal atmosphere for pursuing higher studies. It is located at about 28 kms from district headquarters, Una and is well connected by roads from all sides.</p>
                </div>
                <div className="full">
                  {/* <Link to="/about" className="hvr-radial-out button-theme">About more</Link> */}
                </div>
              </div>
            </div>

              <div class="col-md-6 layout_padding_2">
                <div class="full">
                  <div class="heading_main text_align_left">
                    <h2><span>Principal</span> Message</h2>
                  </div>
                  <div class="full">
                    <p><strong>“You don’t have to be great to start, but you have to start to be great”</strong>
                      SDWG Govt. College Beetan was established on August 23, 2004 when the government of Himachal Pradesh took it over from the private management of Sant Baba Dhangu Wale ji. It has a sprawling campus spread over 50 Kanals. Since its inception, it has added many feathers to its cap specially when NAAC accredited it with grade “B” in December 2016. Our institution offers graduation courses in Arts, Commerce and Science streams along with PG course in English. The institution is well–equipped with modern Hi-Tech classrooms, wi-fi campus, modern library, science Laboratories, English language lab so that faculty may make use of modern teaching –learning methods and students may become tech-savy.
                      Various Clubs and units like NCC, NSS, Rangers and Rovers, Career guidance cell, Red Ribbon Club, Sports Club, Eco-Club, Anti-Drug Cell persistently provide a platform for the betterment and holistic development of the students.
                      We aim that our students graduate and post graduate with qualities and skills that not only enable them to succeed but also equip them to face the challenges of life.
                      I wish best of fortune and success to the students studying presently in the college and welcome new students for the session 2024-25.
                      May all be happy.</p>

                  </div>

                </div>
              </div>
              <div class="col-md-6">
                <div class="full mt-5">
                  <img src={AboutIMG} alt="#" />
                  <div className="about-principal">
                    <p><strong>Dr. Sunita Goyal</strong></p>
                    <p><strong>Principal SDWG Govt. College Beetan</strong></p>
                    <p><strong>Distt. Una H.P.</strong></p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* <!-- end section --> */}
      </div>


      <Footer />
    </>
  );
}

export default About;