import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";

function Health() {
    return (
        <>
            <Header />

            <div className="section">
                <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                    <div class="pattern-overlay">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 title-heading">
                                    <h2>Health</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="container my-5">
                    <h3 class="mb-4">Health Facilities</h3>
                    <p>
                        College health care includes basic facilities, health education, and medical services:
                    </p>
                    <ul class="list-unstyled ps-3">
                        <li class="mb-3">
                            <strong>1) Basic facilities:</strong> College provides first aid and other basic health care in a sick room.
                        </li>
                        <li class="mb-3">
                            <strong>2) Health education:</strong> College offers health education by arranging guest lectures from different resource persons to teach students about physical, mental, emotional, social, and intellectual health.
                        </li>
                        <li class="mb-3">
                            <strong>3) Medical services:</strong> College has a hospital nearby in case of emergency.
                        </li>
                        <li class="mb-3">
                            <strong>4) Yoga and Wellness Centre:</strong> Mental health challenges are common for college students and can be caused by stress, anxiety, depression, and other factors. To tackle this, a “Yoga and Wellness Centre” has been established in the college where regular meditation sessions are organized by <strong>Dr. Gaurav Sharma</strong>.
                        </li>
                    </ul>
                </div>

            </div>

            <Footer />
        </>
    );
}

export default Health;