import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import Mission from "../images/beetanclg.jpg";
import Vission from "../images/recentone.jpg";

function Vision() {
    return (
      <>
        <Header />
        <section>
<div class="container mt-5 mb-5 pb-5 pt-5">
  <div className="set-height">
    <div class="row no-gutters">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        {/* <!--img--> */}
        <img src={Mission} alt="demo-img" class="img-fluid w-100" />
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        {/* <!--text--> */}
        <div class="jumbotron jumbotron-fluid text-white back-prime">
          <div class="container">
            <h1 class="text-center">Vision</h1>
            <p class="text-center set-btm">Our Vision is to embrace the pursuit of excellence both inside and outside the classroom. We encourage critical thinking and emphasize the learning process for holistic development of personality. Our mission is to embrace the pursuit of excellence both inside and outside the classroom. We encourage critical thinking and emphasize the learning process for holistic development of personality. We encourage critical thinking and emphasize the learning process for holistic development</p>
          </div>
        </div>
      </div>
    </div>
    {/* <!--2--> */}
    <div class="row no-gutters">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        {/* <!--text--> */}
        <div class="jumbotron jumbotron-fluid text-white back-prime">
          <div class="container">
            <h1 class="text-center">Mission</h1>
            <p class="text-center set-btm">Our mission is to embrace the pursuit of excellence both inside and outside the classroom. We encourage critical thinking and emphasize the learning process for holistic development of personality. Our mission is to embrace the pursuit of excellence both inside and outside the classroom. We encourage critical thinking and emphasize the learning process for holistic development of personality. We encourage critical thinking and emphasize the learning process for holistic development</p>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        {/* <!--img--> */}
        <img src={Vission} alt="demo-img" class="img-fluid w-100" />
      </div>
    </div>
  </div>
</div>
</section>
  
        <Footer />
      </>
    );
  }
  
  export default Vision;
