import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";

import topback from "../images/beetitle.png";

import { useEffect, useState } from 'react';
function AcadmicBankofCredit(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/acadmicBankofCredit")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Acadmic Bank of Credit</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div className="container mt-5 pb-5">
    <div className="schloarship-main">
    <h1>Academic Bank of Credit (ABC)</h1>
    <p>The Academic Bank of Credit (ABC) is a pivotal concept revolutionizing education by facilitating credit accumulation and transfer across diverse learning experiences. It serves as a dynamic repository where students can accrue credits from formal education, online courses, internships, and more, fostering flexibility and personalized learning paths.</p>
    <p>ABC promotes lifelong learning by recognizing prior knowledge and skills through mechanisms like Recognition of Prior Learning (RPL). This system streamlines credit transferability between institutions, domestically and internationally, enhancing educational efficiency and cost-effectiveness. ABC's emphasis on skills acquisition and recognition empowers learners, amplifying employability and career advancement prospects in today's dynamic job market.</p>

    <h2>Importance of Academic Bank of Credit (ABC)</h2>
    <ul>
        <li><strong>Flexibility in Education:</strong> ABC allows students to accumulate credits from various sources, including formal education, online courses, internships, and work experience, enabling them to tailor their learning paths to their needs and interests.</li>
        <li><strong>Credit Transferability:</strong> It facilitates the seamless transfer of academic credits between educational institutions, both domestically and internationally, reducing administrative hurdles and ensuring recognition of prior learning experiences.</li>
        <li><strong>Cost-effectiveness:</strong> By streamlining credit transferability and recognizing prior learning, ABC promotes efficiency in education, potentially reducing the time and cost required to complete degrees.</li>
        <li><strong>Recognition of Prior Learning (RPL):</strong> ABC acknowledges and values prior learning experiences outside the traditional classroom setting, allowing individuals to have their skills and knowledge assessed and accredited.</li>
        <li><strong>Lifelong Learning:</strong> ABC encourages lifelong learning by providing a mechanism for individuals to continuously acquire new skills and knowledge throughout their lives, thereby enhancing their employability and career advancement prospects.</li>
        <li><strong>Empowerment of Learners:</strong> ABC empowers learners by recognizing diverse skills and knowledge, fostering a culture of continuous learning and skill development, and promoting a learner-centric approach to education.</li>
    </ul>

    <h2>How to Generate Academic Bank of Credit (ABC) ID</h2>
    <p>To generate an Academic Bank of Credit (ABC) ID in a digital locker, you would typically follow these steps:</p>
    <ul>
        <li><strong>Access Digital Locker Platform:</strong> Log in to the digital locker platform provided by your educational institution or the organization managing the ABC system. This could be a website or a mobile application.</li>
        <li><strong>Navigate to ABC Section:</strong> Once logged in, navigate to the section or feature related to the Academic Bank of Credit (ABC). This section may be labeled as "ABC ID Generation," "My Academic Records," or something similar.</li>
        <li><strong>Provide Personal Information:</strong> In the ABC section, you will be prompted to provide personal information. This may include your full name, date of birth, student ID number, contact details, and any other required information.</li>
        <li><strong>Upload Supporting Documents:</strong> You may be required to upload supporting documents to verify your identity and eligibility for participation in the ABC system. These documents could include a government-issued ID, transcripts, certificates, or other relevant records.</li>
        <li><strong>Review and Confirm Information:</strong> Double-check the information you've provided to ensure accuracy. Make any necessary corrections before proceeding.</li>
        <li><strong>Submit Request:</strong> Once you've entered all the required information and uploaded any necessary documents, submit your request to generate an ABC ID.</li>
        <li><strong>Verification Process:</strong> Your information and documents will undergo verification to confirm your identity and eligibility for participation in the ABC system. This process may take some time depending on the institution's policies and procedures.</li>
        <li><strong>ABC ID Generation:</strong> After your information has been successfully verified, your ABC ID will be generated. You will receive a confirmation message indicating that your ABC ID has been created.</li>
        <li><strong>Access ABC Records:</strong> You can now access your academic records, track your credits, and manage your educational journey through the ABC section of the digital locker platform.</li>
        <li><strong>Utilize ABC ID:</strong> Use your ABC ID to accumulate credits from various learning experiences, transfer credits between institutions, and access educational opportunities within the ABC system.</li>
    </ul>

    <p><strong>Instructions:</strong> Keep your ABC ID secure and confidential to protect your academic records and ensure the integrity of the system.</p>

    </div>
</div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
        <Footer />
        </>
    );
}
export default AcadmicBankofCredit;