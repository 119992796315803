import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';

function Infrastructure() {
  const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
  const [user, setUser] = useState([]);
  const fetchData = () => {
    return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/infrastructure")
      .then((response) => response.json())
      .then((data) => setUser(data));
  }
  useEffect(() => {
    fetchData();
  }, [])
  return (
    <>
      <Header />
      <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
        <div class="pattern-overlay">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 title-heading">
                <h2>Infrastructure</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="container">
        <div className="title-1qac">
          <h2></h2>
          <h4></h4>
          <p></p>
        </div>
        <div class="show-list mt-5 pb-5">
          <ul>
            {user && user.length > 0 && user.map((userObj, index) => (
              <li><i class="fa fa-check-circle"></i><a href={Urlmain + userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Infrastructure;